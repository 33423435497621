import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import { AppointmentMifQuestion, MifStructure } from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { clearAppointmentMif } from 'store/mif/mifSlice';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const getSlug = (config: AppointmentMifQuestion['config']) => {
  if (config.type === 'info') {
    return config.group;
  }
  return config.question.value;
};

export const useAppointmentMif = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mifID = '' } = useParams();
  const stepFromQuery = useQuery().get('s') ?? '';
  const redirectStep = useQuery().get('redirectStep') ?? 'date-time';

  const { appointmentMif = [] } = useAppSelector(selectMifInfo);

  const [getStructure, { data, isUninitialized, isLoading: initialLoading }] =
    useLazyGetMifStructureQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();

  const [mifSteps, setMifSteps] = useState<MifStructure['configs']>([]);

  const getStepNames = (): string[] => {
    const stepNames = [
      'intro',
      ...mifSteps.map(({ config }) => String(getSlug(config))),
      'results'
    ];
    return stepNames;
  };

  const handleSubmit = () => {
    submitMif({ id: mifID, body: appointmentMif })
      .unwrap()
      .then(() => {
        dispatch(clearAppointmentMif());
        const redirectStep = sessionStorage.getItem('redirectStep') ?? 'date-time';
        navigate(PathName.CreateAppointment + `?s=${redirectStep}`);
      });
  };

  const getActiveStep = () => {
    if (['intro', 'results'].includes(stepFromQuery)) {
      return stepFromQuery;
    }
    return mifSteps.find(({ config }) => getSlug(config) === stepFromQuery);
  };

  const activeStep = getActiveStep();

  const progress = (): number => {
    if (!activeStep) {
      return 0;
    }
    return (
      getStepNames().findIndex(
        (step) =>
          step ===
          (typeof activeStep === 'string' ? activeStep : String(getSlug(activeStep.config)))
      ) ?? 0
    );
  };

  const moveToStep = (step: 'next' | 'prev' | (string & {}), search?: string) => {
    const buildSearch = (originalSearch: string) => originalSearch + (search ? `&${search}` : '');

    if (step === 'next') {
      const nextStep = getStepNames()[progress() + 1];
      return navigate({ search: buildSearch(`s=${nextStep}`) });
    } else if (step === 'prev') {
      return navigate(-1);
    } else {
      if (getStepNames().includes(step)) {
        return navigate({ search: buildSearch(`s=${step}`) });
      }
      return navigate({ search: buildSearch(`s=${getStepNames()[0]}`) });
    }
  };

  useEffect(() => {
    getStructure(mifID)
      .unwrap()
      .then(({ data }) => {
        setMifSteps(data.configs.toSorted((a, b) => a.order - b.order));
      })
      .catch(handleRequestCatch)
      .finally(() => {
        if (redirectStep) {
          sessionStorage.setItem('redirectStep', redirectStep);
        }
        if (!stepFromQuery) {
          navigate(
            { search: `s=${getStepNames()[0]}` },
            {
              replace: true
            }
          );
        }
      });
  }, []);

  return {
    steps: mifSteps,
    currentQuestion: activeStep,
    description: data?.data?.description || '',
    loading: initialLoading,
    isUninitialized,
    activeStepIndex: progress(),
    flowLength: getStepNames().length,
    moveToStep,
    handleSubmit,
    isSubmittingResults
  };
};
