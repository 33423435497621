import { Common } from '@thecvlb/design-system/lib/src';

import {
  AppointmentMifQuestion,
  ExtendedMifResponseItem,
  MifResponseItem
} from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { setAppointmentMif } from 'store/mif/mifSlice';

import Heading from 'pages/SignUp/WM/parts/Heading';

import Slider from 'features/Slider';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';
import RadioGroup from 'shared/RadioGroup';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';

import FitnessAbilities from './InfoScreen';

interface DynamicQuestionProps {
  config: AppointmentMifQuestion['config'];
  groupTitle?: string;
  mifLength: number;
  onClickContinue: () => void;
  order: number;
}

const DynamicQuestion: React.FC<DynamicQuestionProps> = ({
  config,
  onClickContinue,
  mifLength,
  order,
  groupTitle
}) => {
  const dispatch = useAppDispatch();
  const { isEdit } = useMifNavigate();
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const isInfoScreen = config.type === 'info';

  const answerOnCurrentQuestion = appointmentMif.find((item) =>
    isInfoScreen ? undefined : item.question === config.question.value
  );

  const handleSubmitAnswer = (
    type: AppointmentMifQuestion['config']['type'] | 'textarea',
    value: string | number | boolean,
    option?: string
  ) => {
    let payload: MifResponseItem | ExtendedMifResponseItem = {
      question: '',
      answer: ''
    };
    if (isInfoScreen) {
      return;
    }
    switch (type) {
      case 'info':
        break;
      case 'radio':
        payload = { question: config.question.value, answer: String(value) };
        break;
      case 'textarea':
        payload = {
          question: config.question.value,
          answer: answerOnCurrentQuestion?.answer as string[],
          textAreaFields: [
            ...(answerOnCurrentQuestion?.textAreaFields ?? [])?.filter((i) => i.option !== option),
            {
              option: option ?? '',
              value: String(value)
            }
          ]
        };
        break;
      case 'checkbox':
        if (value === 'none') {
          payload = {
            question: config.question.value,
            answer:
              Array.isArray(answerOnCurrentQuestion?.answer) &&
              answerOnCurrentQuestion?.answer.includes(value)
                ? []
                : [value],
            textAreaFields: undefined
          };
        } else if (typeof value === 'string') {
          if (Array.isArray(answerOnCurrentQuestion?.answer)) {
            const newAnswer = answerOnCurrentQuestion.answer.includes(value)
              ? answerOnCurrentQuestion.answer.filter((v) => v !== value)
              : [...answerOnCurrentQuestion.answer, value];
            if (newAnswer.length > 1 && newAnswer.includes('none')) {
              newAnswer.splice(newAnswer.indexOf('none'), 1);
            }
            payload = { question: config.question.value, answer: newAnswer };
            if (answerOnCurrentQuestion?.textAreaFields && newAnswer.includes('other')) {
              payload.textAreaFields = answerOnCurrentQuestion.textAreaFields;
            }
          } else {
            payload = { question: config.question.value, answer: [value] };
          }
        } else {
          throw new Error('Checkbox value should be a string');
        }
        break;
      case 'text':
        payload = {
          question: config.question.value,
          answer: value,
          questionContext: config.questionContext,
          group: config.group,
          groupTitle
        };
        break;
      default:
        break;
    }
    dispatch(setAppointmentMif([payload]));

    const shouldPreventMoveToTheNextStep = () => {
      if (isInfoScreen) {
        return false;
      }
      const option = config.options.find((i) => i.value === value);
      return (
        ['checkbox', 'textarea', 'text'].includes(type) || (type === 'radio' && !!option?.textArea)
      );
    };
    !shouldPreventMoveToTheNextStep() && onClickContinue();
  };

  const content = () => {
    switch (config.type) {
      case 'info':
        return <FitnessAbilities config={config} onClickContinue={onClickContinue} />;
      case 'radio':
        return (
          <div className="flex h-full flex-col gap-8">
            <RadioGroup
              items={config.options}
              selectedItem={answerOnCurrentQuestion?.answer as string}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('radio', v)}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            {config.options.find((i) => i.value === answerOnCurrentQuestion?.answer)?.textArea && (
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                fullWidthOnMobile
                onClick={onClickContinue}
              >
                {isEdit ? 'Save' : 'Next'}
              </Common.Button>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <div className="flex h-full flex-col gap-8">
            <CheckboxGroup
              items={config.options}
              selectedItems={(answerOnCurrentQuestion?.answer as string[]) ?? []}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('checkbox', String(v))}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!(answerOnCurrentQuestion?.answer as string[])?.length}
              fullWidthOnMobile
              onClick={onClickContinue}
            >
              {isEdit ? 'Save' : 'Next'}
            </Common.Button>
          </div>
        );
      case 'slider':
        return (
          <Slider
            max={10}
            min={1}
            value={undefined}
            onChange={(v) => handleSubmitAnswer('slider', String(v))}
          />
        );
      case 'text':
        return (
          <>
            <div className="flex flex-col gap-6 max-md:h-full">
              <Common.Input
                placeholder={config.answerPlaceholder}
                type="text"
                value={
                  answerOnCurrentQuestion?.answer === 'can_not_complete'
                    ? ''
                    : !!answerOnCurrentQuestion?.answer
                      ? String(answerOnCurrentQuestion?.answer)
                      : ''
                }
                onChange={(e) => handleSubmitAnswer('text', e.target.value.replace(/\D/g, ''))}
              />
              {config.options.length &&
                config.options.map((option) =>
                  option.type === 'checkbox' ? (
                    <Common.Checkbox
                      checked={answerOnCurrentQuestion?.answer === 'can_not_complete'}
                      color="blue"
                      key={option.value}
                      onChange={() => {
                        const newValue =
                          answerOnCurrentQuestion?.answer === 'can_not_complete'
                            ? ''
                            : 'can_not_complete';

                        handleSubmitAnswer('text', newValue);
                      }}
                    >
                      {option.label}
                    </Common.Checkbox>
                  ) : null
                )}
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
                fullWidthOnMobile
                onClick={onClickContinue}
              >
                {isEdit ? 'Save' : 'Next'}
              </Common.Button>
            </div>
          </>
        );
    }
  };

  return (
    <div className="flex h-full flex-col gap-6">
      {config.type !== 'info' && (
        <Heading category={`Question ${order} of ${mifLength}`} title={config.question.label} />
      )}
      {
        <SlideAnimateWrapper className="h-full" key={order}>
          {content()}
        </SlideAnimateWrapper>
      }
    </div>
  );
};

export default DynamicQuestion;
