import { AppointmentMifQuestion, SubmitMifResponseReq } from 'services/mifs/mifs.types';

import { GroupedMifQuestion } from './appointmentMifSummary.types';

export const transformQuestions = ({
  questions,
  answers
}: {
  answers: SubmitMifResponseReq;
  questions: AppointmentMifQuestion[];
}): (GroupedMifQuestion | AppointmentMifQuestion)[] => {
  const patchedQuestions: (GroupedMifQuestion | AppointmentMifQuestion)[] = questions.map((q) => {
    if (q.config.type === 'info') {
      const groupedQuestion: GroupedMifQuestion = {
        group: q.config.group ?? '',
        title: q.config.groupTitle ?? '',
        order: q.order,
        answers: [],
        idToEdit: '',
        isGroup: true
      };
      return groupedQuestion;
    }
    return q;
  });
  questions.forEach((q) => {
    if (q.config.group && q.config.type !== 'info') {
      const item = patchedQuestions.find((g) => 'isGroup' in g && g.group === q.config.group);
      if (item) {
        const answer = answers.find(
          (a) => q.config.type !== 'info' && a.question === q.config.question.value
        );
        if (item && 'isGroup' in item) {
          item.answers.push(
            `${answer?.answer === 'can_not_complete' ? 'Could not complete' : answer?.answer} ${q.config.questionContext}`
          );
          if (!item.idToEdit) {
            item.idToEdit = q.config.question.value;
          }
        }
      }
    }
  });
  return patchedQuestions
    .filter((q) => ('isGroup' in q ? true : !q.config.group))
    .toSorted((a, b) => a.order - b.order);
};
