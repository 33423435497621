import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { useZoomCall } from './index';
import { useLogout } from './useLogout';

export const useLogoutOnIdle = () => {
  const logout = useLogout();
  const { room } = useZoomCall();

  const { reset, start, pause } = useIdleTimer({
    onIdle: () => logout(),
    timeout: 900000, // 15 minutes
    crossTab: true,
    startManually: true
  });

  useEffect(() => {
    if (room) {
      reset();
      pause();
    } else {
      start();
    }
  }, [room, reset, start, pause]);
};
