import { selectMifInfo } from 'store';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import { AppointmentMifSummary, DynamicQuestion, MoreAboutYou } from 'widgets/appointmentMif';
import Header from 'widgets/Header';

import { useAppSelector, useQuery } from 'hooks';
import { useAppointmentMif } from 'hooks/useAppointmentMif';
import { useMifNavigate } from 'hooks/useMifNavigate';

const AppointmentMif = () => {
  const currentStep = useQuery().get('s') ?? '';

  const { appointmentMif = [] } = useAppSelector(selectMifInfo);

  const {
    loading,
    steps,
    currentQuestion,
    isUninitialized,
    activeStepIndex,
    flowLength,
    moveToStep,
    handleSubmit,
    isSubmittingResults,
    description
  } = useAppointmentMif();

  const { isEdit } = useMifNavigate();

  const handleContinue = () => {
    if (isEdit) {
      moveToStep('results');
    } else {
      moveToStep('next');
    }
  };

  const getGroupTitle = (): string => {
    if (typeof currentQuestion === 'string') {
      return '';
    }
    const stepsOfCurrentGroup = steps.filter(
      (step) => !!step.config.group && step.config.group === currentQuestion?.config.group
    );
    const groupTitle = stepsOfCurrentGroup.find((item) => item.config.type === 'info');
    if (!!groupTitle && 'groupTitle' in groupTitle.config) {
      return groupTitle.config.groupTitle ?? '';
    }
    return '';
  };

  const content = () => {
    switch (currentStep) {
      case 'intro':
        return (
          <SlideAnimateWrapper className="h-full" key="intro">
            <MoreAboutYou
              description={
                description ||
                'Before creating your appointment, we’ll ask a few diet and lifestyle questions.'
              }
              onClickContinue={() => moveToStep('next')}
            />
          </SlideAnimateWrapper>
        );
      case 'results':
        return (
          <SlideAnimateWrapper key="results">
            <AppointmentMifSummary
              answers={appointmentMif}
              loading={isSubmittingResults}
              questions={steps}
              onClickContinue={handleSubmit}
              onClickEdit={(step, shouldRedirect) => {
                moveToStep(step, shouldRedirect ? 'step-action=edit' : undefined);
              }}
            />
          </SlideAnimateWrapper>
        );
      default:
        return (
          !!currentQuestion &&
          typeof currentQuestion !== 'string' && (
            <DynamicQuestion
              config={currentQuestion.config}
              groupTitle={getGroupTitle()}
              mifLength={steps.length}
              order={currentQuestion.order}
              onClickContinue={handleContinue}
            />
          )
        );
    }
  };

  return loading || isUninitialized ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col p-4">
      <Header
        flowLength={flowLength}
        step={activeStepIndex}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="w-full max-w-[500px] grow md:mx-auto">{content()}</div>
    </div>
  );
};

export default AppointmentMif;
