import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import {
  useDeletePaymentMethodMutation,
  useLazyGetCreditCardInformationQuery,
  useLazyGetMyAccountQuery,
  useUpdatePaymentProfileMutation
} from 'services/myAccount/myAccount';

import { selectLookup, selectUser } from 'store';

import { buildBodyForPaymentUpdate } from 'pages/MyAccount/components/BillingDetails/billingDetails.settings';

import PaymentMethodForm from 'features/PaymentMethodForm/PaymentMethodForm';
import { notifySuccess } from 'shared/Toast/Toast';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { useAppSelector } from 'hooks';
import { useExpiredCard } from 'hooks/useExpiredCard';
import { useRouteMatch } from 'hooks/useRouteMatch';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { PaymentMethodPurpose } from 'models/payments.types';

import { PaymentMethodActionsProps } from './paymentMethodActions.types';

const PaymentMethodActions: React.FC<PaymentMethodActionsProps> = ({
  isOpen,
  onClose,
  ppId = '',
  isPayPalConnected,
  isInsuranceUser,
  loading,
  selectedPPType,
  isDefault,
  activeTab
}) => {
  const [deletePaymentMethod, { isLoading: isLoadingDelete }] = useDeletePaymentMethodMutation();
  const [updatePaymentProfile, { isLoading: isLoadingUpdate }] = useUpdatePaymentProfileMutation();
  const [getPaymentMethodsInformation, { isLoading: isLoadingGet }] =
    useLazyGetCreditCardInformationQuery();
  const [getMyAccount] = useLazyGetMyAccountQuery();
  const isBillingDetails = useRouteMatch(PathName.BillingDetails)?.isExact;
  const isCheckout = useRouteMatch(PathName.Checkout)?.isExact;
  const [innerLoading, setInnerLoading] = useToggle(false);
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePlanId, hasPastDueInvoices } = useAppSelector(selectUser);

  const currentPlan = membershipPlans.find((plan) => plan._id === activePlanId);

  const { daysUntilCardExpiration, isExpired } = useExpiredCard(ppId);

  const handleUpdatePaymentDetails = () => {
    setInnerLoading(true);
    Promise.all([
      getMyAccount().unwrap(),
      ...(isBillingDetails || isCheckout ? [getPaymentMethodsInformation().unwrap()] : []),
      ...(isInsuranceUser
        ? [getPaymentMethodsInformation({ isInsuranceSubscription: true }).unwrap()]
        : [])
    ])
      .then(() => onClose(true))
      .finally(() => setInnerLoading(false));
  };

  const handleDeletePaymentMethod = (displayNotification = true) => {
    if (ppId === 'new') {
      return handleUpdatePaymentDetails();
    }

    deletePaymentMethod({ id: ppId, isInsuranceSubscription: selectedPPType === 'appointments' })
      .unwrap()
      .then((data) => {
        displayNotification && notifySuccess(data.message);
        isBillingDetails && getPaymentMethodsInformation().unwrap();
      })
      .then(handleUpdatePaymentDetails)
      .catch(handleRequestCatch);
  };

  const handleUpdatePaymentMethod = async (
    formInfo: PaymentFormFields | string,
    types: PaymentMethodPurpose[]
  ) => {
    try {
      for await (const type of types) {
        await updatePaymentProfile({
          ...buildBodyForPaymentUpdate(
            formInfo,
            'POST',
            typeof formInfo === 'string' ? 'paypal_account' : 'credit_card'
          ),
          isInsuranceSubscription: type === 'appointments'
        }).unwrap();
      }
      notifySuccess('Payment method updated successfully.');
      handleDeletePaymentMethod(false);
    } catch (e) {
      handleRequestCatch(e as MessageEvent, 'Error updating payment method.');
    }
  };

  return (
    <Common.Modal isOpen={isOpen} size="base" persist>
      {!!currentPlan && (daysUntilCardExpiration < 30 || hasPastDueInvoices) && (
        <Common.Alert
          className="mb-8"
          type={hasPastDueInvoices || isExpired ? 'error' : 'warning'}
          colorableBackground
        >
          <b>
            {hasPastDueInvoices
              ? 'Billing past due.'
              : isExpired
                ? 'Credit card expired.'
                : 'Your credit card is about to expire.'}
            &nbsp;
          </b>
          {hasPastDueInvoices
            ? 'Please update your payment method to access prescription renewals, appointments, and care team messages.'
            : `Update your payment method to continue using ${currentPlan.planName} features.`}
        </Common.Alert>
      )}
      <PaymentMethodForm
        action={ppId === 'new' ? 'add' : 'edit'}
        isLoading={isLoadingGet || isLoadingDelete || isLoadingUpdate || !!loading || innerLoading}
        isPayPalConnected={isPayPalConnected}
        isShowOptions={isInsuranceUser && isBillingDetails}
        preselectedType={isInsuranceUser ? activeTab || 'appointments' : undefined}
        onCancel={() => onClose(false)}
        onConfirm={handleUpdatePaymentMethod}
        onDelete={isDefault ? undefined : handleDeletePaymentMethod}
      />
    </Common.Modal>
  );
};

export default PaymentMethodActions;
