import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import Heading from 'pages/SignUp/WM/parts/Heading';

import { transformQuestions } from './appointmentMifSummary.settings';
import { AppointmentMifSummaryProps, MifItemProps } from './appointmentMifSummary.types';

const MifItem = ({
  options,
  title,
  answer,
  onEdit,
  id,
  textAreaAnswers,
  isLastItem = false,
  isGroupQuestion
}: MifItemProps) => {
  const parsedAnswer = (): JSX.Element => {
    switch (typeof answer) {
      // for radio buttons
      case 'string':
        const option = options.find((o) => o.value === answer)?.label;
        return (
          <>
            <span>{option}</span>
            {!!textAreaAnswers &&
              Number(textAreaAnswers?.length) > 0 &&
              textAreaAnswers.map((t) => <span className="block">{t.value}</span>)}
          </>
        );
      // for checkboxes with textareas or for group questions
      case 'object':
        return (
          <>
            {answer.map((a, i) => {
              // right now no options means that it's a grouped answer
              if (isGroupQuestion) {
                return (
                  <span className="block" key={i}>
                    {a}
                  </span>
                );
              }
              const option = options.find((o) => o.value === a);
              return (
                <span className="block" key={i}>
                  {!option?.textArea
                    ? option?.label
                    : `${option.label}, ${textAreaAnswers?.find((t) => t.option === option?.value)?.value}`}
                </span>
              );
            })}
          </>
        );
      default:
        return <>{answer}</>;
    }
  };

  return (
    <div
      className={classNames('flex flex-col gap-1 pb-6', {
        'border-b border-gray-200': !isLastItem
      })}
    >
      <div className="flex items-center gap-1">
        <Common.Icon className="mr-1 flex-none text-secondary" name="check"></Common.Icon>
        <span className="font-bold text-gray-700">{title}</span>
        <button
          className="ml-auto mr-3 block pl-2 text-sm font-bold text-gray"
          data-testid="edit_btn"
          onClick={() => onEdit(id, !isGroupQuestion)}
        >
          Edit
        </button>
      </div>
      <span className="ml-6 text-mSm font-semibold text-gray md:text-sm">{parsedAnswer()}</span>
    </div>
  );
};

const AppointmentMifSummary: React.FC<AppointmentMifSummaryProps> = ({
  answers,
  onClickContinue,
  onClickEdit,
  loading,
  questions
}) => {
  const groupedQuestions = transformQuestions({ answers, questions });

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col gap-6">
        <Heading
          category="Summary"
          subtitle="Please review your responses."
          title="Check your answers"
        />
        <div className="rounded-2xl border border-gray-200 bg-white p-4">
          <div className="flex flex-col gap-6">
            {groupedQuestions.map((item, i) => {
              if ('isGroup' in item) {
                const lengthOfCompleted = item.answers.filter(
                  (a) => !a.toLowerCase().includes('could not complete')
                ).length;
                return (
                  <MifItem
                    answer={[`${lengthOfCompleted}/${item.answers.length}`, ...item?.answers]}
                    id={item.idToEdit}
                    isLastItem={i === groupedQuestions.length - 1}
                    key={item.idToEdit}
                    options={[]}
                    title={item.title}
                    isGroupQuestion
                    onEdit={onClickEdit}
                  />
                );
              }
              if (item.config.type === 'info') {
                return null;
              }
              const answer = answers.find(
                (a) => item.config.type !== 'info' && a.question === item.config.question.value
              );

              return (
                <MifItem
                  answer={answer?.answer}
                  id={item.config.question.value}
                  isLastItem={i === groupedQuestions.length - 1}
                  key={item.config.question.value}
                  options={item.config.options}
                  textAreaAnswers={answer?.textAreaFields}
                  title={item.config.question.label}
                  onEdit={onClickEdit}
                />
              );
            })}
          </div>
        </div>
        <Common.Button
          className="mx-auto max-md:sticky max-md:bottom-4"
          color="blue"
          isLoading={loading}
          fullWidthOnMobile
          onClick={onClickContinue}
        >
          Continue
        </Common.Button>
      </div>
    </div>
  );
};

export default AppointmentMifSummary;
