import { useEffect, useState } from 'react';
import { useLockBodyScroll, useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';

import { selectLookup, selectOrchestrate, selectSignUp, selectUser } from 'store';
import { setPayment } from 'store/orchestrate-signup/orchestrateSlice';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';
import { getScalesPriceByPPLength } from 'containers/SignUp/signup.settings';
import AdvantageItem from 'features/AdvantageItem';
import NewWeightGraph from 'features/NewWeightGraph';
import LimitedAccessPlan from 'modals/LimitedAccessPlan';
import ScaleDetails from 'modals/ScaleDetails';
import { notifyError } from 'shared/Toast/Toast';
import DynamicReviewsList from 'widgets/reviews/DynamicReviewsList';
import WMScales from 'widgets/signUp/WMScales';

import {
  BLUE_LINE_ONBOARDING_PRICE_POINTS,
  SEMAGLUTIDE_PRICE_POINT,
  WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS,
  WM_ONBOARDING_PRICE_POINTS_SPECIFIC_FLOW,
  WM_VALID_ONBOARDING_PRICE_POINTS
} from 'constants/onboarding';
import { useAppDispatch, useAppSelector } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import useWidth from 'hooks/useWidth';
import {
  BLUE_LINE_ADVANTAGES,
  FAQ_WM,
  WM_DUMMY_ADVANTAGES,
  WM_INSURANCE_DUMMY_ADVANTAGES
} from 'utils/constants';
import { FeatureFlag, FlowTypes, PlanCodes } from 'utils/enums';
import { applyPromoCode, getScalesAddonData } from 'utils/helpers';

import Heading from '../parts/Heading';
import ListOfResearch from '../parts/ListOfResearch';
import MedicationAtSelectPlan from '../parts/MedicationAtSelectPlan';
import PlanCardItem from '../parts/PlanCardItem';
import PlanRadioButton from '../parts/PlanRadioButton';
import WMQuestionsList from '../parts/QuestionsList/WMQuestionsList';
// import WeightLossMoneyBack from '../parts/WeightLossMoneyBack';
import { getNewTargetWeight } from '../wm.settings';

import { Medication } from 'models/medication.types';
import { PricePoint } from 'models/plans.types';

import {
  brandedMedicationAdvantages,
  calculateWeightLose,
  compoundMedicationAdvantages,
  DUMMY_REVIEWS_WM,
  semaglutideExtendedAdvantages
} from './selectPlan.settings';

const itemClassName = 'flex items-center gap-1 text-mSm leading-3 text-primary-700 md:text-sm';

export const BL_SELECT_PLAN_FAQ = [
  {
    id: '1',
    text: `This program combines groundbreaking GLP-1 medications, lab testing, and the knowledge of leading clinicians to create a highly effective approach to weight loss. We'll take your unique metabolic profile and lifestyle habits into account to help ensure you lose that extra weight - one of the most important things you'll ever do for your health.`,
    title: 'What is the LifeMD Weight Management Program?'
  },
  {
    id: '2',
    text: 'Licensed medical providers deliver comprehensive care, including your initial visit, coaching provided by your weight loss team and ongoing medical check-ins from the comfort of your home. Lab work may be required.',
    title: 'Is the weight loss program completely online?'
  },
  {
    id: '3',
    text: (
      <ul className="flex flex-col gap-4">
        <li>
          <span className="font-bold">Access to GLP-1 medications:</span> As part of our commitment
          to offering cutting-edge treatment options, our program includes access to brand-name and
          compounded GLP-1 medications.
        </li>
        {/* <li>
          <span className="font-bold">Insurance coverage assistance:</span>
          Navigating insurance can be complex, which is why our support team helps you determine if
          your insurance covers GLP-1 medication.
        </li> */}
        <li>
          <span className="font-bold"> Metabolic testing: </span>
          Unlock the secrets to your body's unique metabolism with our comprehensive metabolic
          testing, which will help us create a tailored plan that works best for you.
        </li>
        <li>
          <span className="font-bold">Ongoing provider care: </span>
          Expert medical providers will monitor your progress, make adjustments to your treatment
          plan as needed, and provide continuous care to ensure your long-term success.
        </li>
      </ul>
    ),
    title: 'What do I get when I sign up? '
  },
  {
    id: '4',
    text: `GLP-1s are groundbreaking medications that have shown remarkable results in clinical studies. GLP-1 medications help regulate and improve the body's weight loss factors - such as blood sugar response and insulin. By changing these factors, the gut will send signals to the brain to improve metabolic function and regulate digestion and appetite, helping patients feel fuller, longer. In studies, patients lost 15-20% of their body weight* with GLP-1 medication.`,
    title: 'How do GLP-1 medications work?'
  },
  {
    id: '5',
    text: 'The costs of GLP-1 medications are not included in the program fees unless explicitly stated as otherwise. Our comprehensive program includes expert clinician consultations, dedicated coaching, and essential lab work.',
    title: 'Is the cost of medication included in the program fees?'
  }
];

const SelectPlan: React.FC<MixedSignupStepProps> = ({ moveToStep, selectedFlow }) => {
  useTitle('Select plan');

  const showScales =
    useFlag(FeatureFlag.OnboardingWMScales) &&
    selectedFlow !== 'authorized' &&
    [
      FlowTypes.WeightManagementFlow,
      FlowTypes.BlueLineFlow,
      FlowTypes.WeightManagementBalladHealth
    ].includes(selectedFlow);

  const { isMobile } = useWidth();
  const dispatch = useAppDispatch();

  const [selectedPP, setSelectedPP] = useState<PricePoint>();
  const [isOpenPopup, togglePopup] = useToggle(false);
  const [isScalePopupOpen, toggleScalePopup] = useToggle(false);

  const { membershipPlans } = useAppSelector(selectSignUp);
  const { firstName: authorizedUserFirstName } = useAppSelector(selectUser);
  const { membershipPlans: authorizedUserMembershipPlans } = useAppSelector(selectLookup);
  const {
    mif_qa: mifQA,
    user: { firstName },
    payment: { product_price_point_handle, includesScales }
  } = useAppSelector(selectOrchestrate);
  const { send, isLoading } = useSubmitOrchestrateForm();

  useLockBodyScroll(showScales && isScalePopupOpen);

  const plans = membershipPlans.length > 0 ? membershipPlans : authorizedUserMembershipPlans;
  const WMPLan = plans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const insuranceAnswer = mifQA.find((a) => a.question === 'insurance_details')?.answer ?? '';
  const WMPricePoints = WMPLan?.pricePoints;
  const priceWithDiscount = getScalesPriceByPPLength(selectedPP);

  const ver = String(mifQA.find((q) => q.question === 'should_i_use_insurance')?.answer);
  const currentWeight = Number(mifQA.find((q) => q.question === 'weight')?.answer);
  const currentBMI = mifQA.find((q) => q.question === 'bmi_value')?.answer ?? null;
  const newTargetWeight = getNewTargetWeight(currentWeight);
  const semaglutidePP = WMPricePoints?.find((p) => p.planPricePointId === SEMAGLUTIDE_PRICE_POINT);
  const threeMonthPP = WMPricePoints?.find((p) => p.paymentInterval.qty === 3);
  const isMedicationFlow = selectedFlow === FlowTypes.WeightManagementFlowInsuranceMedications;
  const isSpecificWMFlow = selectedFlow === FlowTypes.WeightManagementSpecific;
  const isBlueLineFlow = selectedFlow === FlowTypes.BlueLineFlow;

  const showSpecificPPs =
    ((['2', '3', '4'].includes(ver) || insuranceAnswer === 'uninsured') &&
      ![
        FlowTypes.WeightManagementFlowInsurance,
        FlowTypes.WeightManagementFlow,
        FlowTypes.WeightManagementFlowOptavia,
        FlowTypes.WeightManagementSpecific
      ].includes(selectedFlow as FlowTypes)) ||
    isMedicationFlow;
  const showAllOptions = ver === '4' || insuranceAnswer === 'uninsured';
  const willUseInsurance =
    mifQA.find((q) => q.question === 'will_use_insurance_for_medications')?.answer ?? false;
  const selectedMedication = mifQA.find((q) => q.question === 'selected_medication')
    ?.answer as unknown as Medication | { drug_name: 'TBD' } | undefined;

  const onToggleScales = () => dispatch(setPayment({ includesScales: !includesScales }));
  useEffect(() => {
    if (WMPricePoints) {
      if (isBlueLineFlow) {
        setSelectedPP(
          WMPricePoints.find((p) => BLUE_LINE_ONBOARDING_PRICE_POINTS.includes(p.planPricePointId))
        );
      } else if (isSpecificWMFlow) {
        setSelectedPP(
          WMPricePoints.find((p) =>
            WM_ONBOARDING_PRICE_POINTS_SPECIFIC_FLOW.includes(p.planPricePointId)
          )
        );
      } else if (isMedicationFlow) {
        if (selectedMedication?.drug_name === 'Semaglutide') {
          setSelectedPP(semaglutidePP);
        } else {
          setSelectedPP(threeMonthPP);
        }
      } else if (
        selectedFlow !== 'authorized' &&
        [FlowTypes.WeightManagementFlowAsync, FlowTypes.WeightManagementBalladHealth].includes(
          selectedFlow
        ) &&
        (['2', '3'].includes(ver) || insuranceAnswer === 'uninsured')
      ) {
        setSelectedPP(semaglutidePP);
      } else if (willUseInsurance) {
        setSelectedPP(
          WMPricePoints.find((p) =>
            WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS.includes(p.planPricePointId)
          )
        );
      } else if (
        product_price_point_handle &&
        filteredPricePoints.some((p) => p.planPricePointId === product_price_point_handle)
      ) {
        setSelectedPP(WMPricePoints.find((p) => p.planPricePointId === product_price_point_handle));
      } else {
        const defaultPP = WMPricePoints.find((p) => p.isDefault);
        setSelectedPP(showSpecificPPs ? semaglutidePP : defaultPP);
      }
    }
  }, [WMPricePoints]);

  const handleSubmit = () => {
    if (selectedPP) {
      const couponCode = applyPromoCode(selectedPP.paymentInterval.qty);
      // find data when be will add pp
      const addonData = !showScales ? null : getScalesAddonData(selectedPP, includesScales);
      send(
        'payment',
        {
          coupon_codes: !couponCode ? [] : [couponCode],
          product_handle: WMPLan?.planCode,
          product_price_point_handle: selectedPP.planPricePointId,
          addOns: !!addonData ? [addonData] : []
        },
        () => {
          moveToStep('next');
        }
      );
    } else {
      notifyError('Something went wrong. Please try again later.');
    }
  };

  const filteredPricePoints = WMPricePoints
    ? [...WMPricePoints]
        ?.filter((p) =>
          (isBlueLineFlow
            ? BLUE_LINE_ONBOARDING_PRICE_POINTS
            : isSpecificWMFlow
              ? WM_ONBOARDING_PRICE_POINTS_SPECIFIC_FLOW
              : willUseInsurance
                ? WM_INSURANCE_VALID_ONBOARDING_PRICE_POINTS
                : WM_VALID_ONBOARDING_PRICE_POINTS
          ).includes(p.planPricePointId)
        )
        .sort((a) => (a.paymentInterval.qty === 3 ? -1 : 1))
    : [];

  return (
    <div className="flex flex-col place-items-center gap-6">
      {showScales && (
        <ScaleDetails
          isAddedToCart={!!includesScales}
          isOpen={isScalePopupOpen}
          priceWithDiscount={priceWithDiscount}
          onClickAdd={onToggleScales}
          onClose={toggleScalePopup}
        />
      )}
      <LimitedAccessPlan isOpen={isOpenPopup} onClose={togglePopup} />
      <Heading
        category="Sign up"
        title={`${selectedFlow === 'authorized' ? authorizedUserFirstName : firstName}, your prescription GLP-1 weight loss program is ready!`}
      />
      <div className="flex items-start gap-6 self-stretch max-md:flex-col md:gap-[44px]">
        <div className="flex flex-col gap-6 md:w-[360px] md:min-w-[360px]">
          <div className="flex flex-col gap-3 rounded-2xl bg-white p-4 shadow md:gap-4">
            <div className="flex flex-col gap-3">
              <div className={itemClassName}>
                <Common.Icon className="w-[18px]" name="scale-outline" />
                <p className="font-bold">Goal weight:</p>
                <span className="ml-1">{getNewTargetWeight(currentWeight)} lbs</span>
              </div>
              <div className={itemClassName}>
                <Common.Icon className="w-[18px]" name="list-view-outline" />
                <p className="font-bold">Target weight loss:</p>
                <span className="ml-1">
                  {currentWeight - Number(getNewTargetWeight(currentWeight))} lbs
                </span>
              </div>
              <div className={itemClassName}>
                <Common.Icon className="w-[18px]" name="heartbeat" />
                <p className="font-bold">Current BMI:</p>
                <span className="ml-1">{currentBMI}</span>
              </div>
              {!isBlueLineFlow && (
                <div className={itemClassName}>
                  <Common.Icon className="w-[18px]" name="prescription-outline" />
                  <p className="font-bold">Wegovy®, Ozempic®.</p>
                </div>
              )}
            </div>
            <div>
              <NewWeightGraph weightPoints={[currentWeight, newTargetWeight]} />
            </div>
          </div>
          <div className={itemClassName}>
            <Common.Icon className="w-[18px]" name="scale-outline" />
            <p className="font-bold">Estimated weight loss:</p>
            {!!selectedPP && (
              <span className="ml-1">
                {calculateWeightLose(currentWeight, selectedPP.paymentInterval.qty.toString())}
                lbs
              </span>
            )}
          </div>
          {/* <p className="pb-2 text-sm text-gray-600 max-md:hidden">
            <b>30-day money back guarantee.</b> You may cancel by email, by chat or by calling us
            toll-free at <b>+1-800-852-1575</b>
          </p> */}
        </div>
        <div className="flex w-full flex-col gap-6 md:grow md:gap-3">
          <h4 className="text-m2xl font-bold text-primary-700 md:text-2xl">
            {willUseInsurance || isSpecificWMFlow || isBlueLineFlow
              ? 'Your plan'
              : 'Next steps: select your plan'}
          </h4>
          <div className="flex grow flex-col gap-3 max-md:w-full" data-testid="wm_plans">
            {showSpecificPPs ? (
              <>
                <div className="flex flex-col gap-6 md:grow">
                  <h3>
                    This comprehensive program provides you with a custom-tailored plan,
                    cutting-edge medication, and unwavering support every step of the way.
                  </h3>
                  {isMedicationFlow ? (
                    <>
                      {selectedMedication?.drug_name === 'Semaglutide' && !!semaglutidePP && (
                        <PlanRadioButton
                          content={{
                            advantages: compoundMedicationAdvantages,
                            newPrice: semaglutidePP.totalCost,
                            oldPrice: '450',
                            subtitle: '1 month plan',
                            title: 'Semaglutide Medication'
                          }}
                          pricePoint={semaglutidePP}
                          selectedValue={selectedPP?.planPricePointId}
                          onSelect={setSelectedPP}
                        />
                      )}
                      {selectedMedication?.drug_name !== 'Semaglutide' && !!threeMonthPP && (
                        <PlanRadioButton
                          content={{
                            advantages: brandedMedicationAdvantages,
                            newPrice: `${(Number(threeMonthPP.totalCost) * parseFloat('75%')) / 100}`,
                            oldPrice: threeMonthPP.totalCost,
                            subtitle: '3 month plan',
                            title: 'Branded GLP-1 Program'
                          }}
                          pricePoint={threeMonthPP}
                          selectedValue={selectedPP?.planPricePointId}
                          onSelect={setSelectedPP}
                        />
                      )}
                    </>
                  ) : (
                    <div className="flex flex-col gap-3">
                      {!!threeMonthPP && showAllOptions && (
                        <PlanRadioButton
                          content={{
                            advantages: brandedMedicationAdvantages,
                            newPrice: `${(Number(threeMonthPP.totalCost) * parseFloat('75%')) / 100}`,
                            oldPrice: threeMonthPP.totalCost,
                            subtitle: '3 month plan',
                            title: 'Branded GLP-1 Program'
                          }}
                          pricePoint={threeMonthPP}
                          selectedValue={selectedPP?.planPricePointId}
                          onSelect={setSelectedPP}
                        />
                      )}
                      {!!semaglutidePP && (
                        <PlanRadioButton
                          content={{
                            advantages: showAllOptions
                              ? compoundMedicationAdvantages
                              : semaglutideExtendedAdvantages,
                            newPrice: semaglutidePP.totalCost,
                            oldPrice: '450',
                            subtitle: '1 month plan',
                            title: 'Semaglutide Medication'
                          }}
                          pricePoint={semaglutidePP}
                          selectedValue={selectedPP?.planPricePointId}
                          onSelect={setSelectedPP}
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              filteredPricePoints?.map((p) => (
                <button
                  key={p.planPricePointId}
                  onClick={() => {
                    setSelectedPP(p);
                    if (p.paymentInterval.qty === 12 && showScales) {
                      dispatch(setPayment({ includesScales: true }));
                    }
                  }}
                >
                  <PlanCardItem
                    estimatedWL={calculateWeightLose(
                      currentWeight,
                      p.paymentInterval.qty.toString()
                    )}
                    isSelected={p.planPricePointId === selectedPP?.planPricePointId}
                    pricePoint={p}
                    showScales={showScales}
                  />
                </button>
              ))
            )}
          </div>
          {isBlueLineFlow && (
            <span className="text-center text-mSm text-gray md:text-sm">
              The LifeMD Weight Management Program includes prescriptions to GLP-1 medications when
              medically appropriate, ongoing medical support for prescription renewals each month,
              dosage increase guidance, and lab work.
            </span>
          )}
        </div>
      </div>
      {showScales && (
        <div>
          <div className="fluid bg-primary-100 md:mb-8 md:ml-auto md:!w-auto md:rounded-2xl md:p-6">
            <div className="md:mx-auto md:max-w-[704px]">
              <WMScales
                isAddedToCart={!!includesScales}
                priceWithDiscount={priceWithDiscount}
                onClickAdd={onToggleScales}
                onClickLearnMore={toggleScalePopup}
              />
            </div>
          </div>
        </div>
      )}
      {/* {!isMedicationFlow && (
        <>
          <Common.Button color="blue" disabled={isLoading} fullWidthOnMobile onClick={handleSubmit}>
            Continue to checkout
          </Common.Button>
          <div className="flex items-center gap-1 text-mSm text-primary-700 md:text-sm">
            <span>Backed by the</span>
            <span className="text-mSm font-bold md:text-sm">LifeMD Weight Loss Guarantee</span>
            <Common.Icon className="size-6 stroke-primary-700 text-white" name="check-circle" />
          </div>
        </>
      )} */}
      {showSpecificPPs && (
        <>
          {!isMedicationFlow && (
            <>
              <div className="h-px w-full bg-gray-300 max-md:hidden"></div>
              <div className="flex flex-col gap-2 text-primary-700">
                <h4 className="text-mBase font-bold">See what’s included with your treatment.</h4>
                <h3 className="text-mXl font-semibold">
                  LifeMD offers compounded GLP-1s including Semaglutide & Tirzepatide.
                </h3>
              </div>
            </>
          )}
          {isMedicationFlow && !!selectedMedication && (
            <MedicationAtSelectPlan
              loading={isLoading}
              medication={selectedMedication}
              onClickContinue={handleSubmit}
              onClickEdit={() => moveToStep('prev')}
            />
          )}
          {showAllOptions ? (
            <div className="flex w-full gap-3 max-md:flex-col">
              <div className="md:w-1/2">
                <div className="rounded-2xl bg-white shadow">
                  <div className="flex items-center gap-2 rounded-t-2xl bg-primary-100 p-4">
                    <Common.Logo className="size-5" name="cross" />
                    <h4 className="text-mBase font-bold text-primary md:text-lg">
                      Branded name medication
                    </h4>
                  </div>
                  <div className="flex flex-col gap-2.5 p-4">
                    <div className="flex flex-col gap-1 rounded-lg bg-gray-50 p-4 text-primary-700">
                      <span className="text-mXs">Program cost</span>
                      {threeMonthPP?.monthlyPrice && (
                        <span className="text-m2xl font-bold">
                          ${threeMonthPP?.monthlyPrice}/mo
                        </span>
                      )}
                      <span className="text-mSm">Medication through insurance co-pay</span>
                      <span className="text-mXs text-gray">Medication not included</span>
                    </div>
                    <div className="flex flex-col gap-1 py-1.5 text-primary-700">
                      <h2 className="font-bold md:text-lg">Medication options:</h2>
                      <span>Wegovy®, Ozempic®, Zepbound®</span>
                    </div>
                    <h3 className="text-mBase font-bold text-primary-700">What’s included:</h3>
                    {[
                      'Insurance assistance for medication',
                      'Insurance prior authorization',
                      'Full Medical history assessment',
                      'Weight loss related labs (free)',
                      'Clinically tailored treatment plan',
                      'Rx sent to your pharmacy or shipped',
                      'Ongoing consultations to increase medication dosage if needed'
                    ].map((a) => (
                      <div className="flex gap-2 text-primary-700" key={a}>
                        <Common.Icon className="flex-none" name="check" />
                        <span className="text-mSm md:text-sm">{a}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="rounded-2xl bg-white shadow">
                  <div className="flex items-center gap-2 rounded-t-2xl bg-primary-100 p-4">
                    <Common.Logo className="size-5" name="cross" />
                    <h4 className="text-mBase font-bold text-primary md:text-lg">
                      Compounded medication
                    </h4>
                  </div>
                  <div className="flex flex-col gap-2.5 p-4">
                    <div className="flex flex-col gap-1 rounded-lg bg-yellow-100 p-4 text-primary-700">
                      <span className="text-mXs">Program + Medication cost</span>
                      {semaglutidePP?.totalCost && (
                        <span className="text-m2xl font-bold">${semaglutidePP?.totalCost}/mo</span>
                      )}
                      <span className="text-mSm">Semaglutide GLP-1</span>
                      <span className="text-mXs text-gray">Medication included</span>
                    </div>
                    <div className="flex flex-col gap-1 py-1.5 text-primary-700">
                      <h2 className="font-bold md:text-lg">Medication options:</h2>
                      <span>Semaglutide & Tirzepatide</span>
                    </div>
                    <h3 className="text-mBase font-bold text-primary-700">What’s included:</h3>
                    {[
                      'Medication: Semaglutide',
                      'Full Medical history assessment',
                      'Weight loss related labs (no addtl. cost)',
                      'Medication shipped directly to your door',
                      'Clinical oversight with tailored treatment plan',
                      'Ongoing consultations to increase medication dosage if needed'
                    ].map((a) => (
                      <div className="flex gap-2 text-primary-700" key={a}>
                        <Common.Icon className="flex-none" name="check" />
                        <span className="text-mSm md:text-sm">{a}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            !isMedicationFlow && (
              <div className="w-full rounded-2xl bg-white shadow">
                <div className="flex items-center gap-2 rounded-t-2xl bg-primary-100 p-4">
                  <Common.Logo className="size-5" name="cross" />
                  <h4 className="text-mBase font-bold text-primary md:text-lg">
                    Compounded GLP-1 Medication
                  </h4>
                </div>
                <div className="flex flex-col gap-2.5 p-4">
                  <h3 className="text-mBase font-bold text-primary-700">What’s included:</h3>
                  <div className="grid gap-2.5 md:grid-flow-col md:grid-cols-2 md:grid-rows-3 md:py-4">
                    {[
                      'Monthly supply of Semaglutide Medication',
                      'Full Medical history assessment',
                      'Weight loss related labs (no addtl. cost)',
                      'Free overnight shipping',
                      'Clinical oversight + tailored treatment to your door',
                      'Ongoing consultations with LifeMD provider'
                    ].map((a) => (
                      <div className="flex gap-2 text-primary-700" key={a}>
                        <Common.Icon className="flex-none" name="check" />
                        <span className="text-mSm md:text-sm">{a}</span>
                      </div>
                    ))}
                  </div>
                  {!!semaglutidePP && (
                    <div className="flex flex-col gap-2 rounded-lg bg-yellow-100 p-4 text-base">
                      <span className="font-bold">SAVE 34% OFF ON SEMAGLUTIDE MEDICATION </span>
                      <p className="flex items-center gap-1 text-primary-700">
                        <span className="text-red line-through">$450</span>
                        <span className="font-bold">${semaglutidePP?.monthlyPrice}/mo.</span>
                        <span>Cancel anytime.</span>
                      </p>
                    </div>
                  )}
                  <Common.Button
                    className="!w-full justify-center"
                    color="blue"
                    onClick={handleSubmit}
                  >
                    Start with Compound Rx
                  </Common.Button>
                  <div className="flex flex-col items-center gap-2 py-2 text-primary-700">
                    <span className="font-bold">WEIGHT LOSS MONEY-BACK GUARANTEE</span>
                    <span className="text-mSm md:text-sm">
                      Lose 10% of your weight or your money back*
                    </span>
                  </div>
                </div>
              </div>
            )
          )}
        </>
      )}
      {!isMedicationFlow && (
        <>
          {/* <div className="h-px w-full bg-gray-300 max-md:hidden"></div> */}
          <div className="flex items-stretch gap-8 self-stretch max-md:flex-col md:gap-6">
            <div className="md:max-w-[340px]">
              <h3 className="mb-2 text-mBase font-bold text-primary-700 md:text-xl md:font-semibold">
                {selectedFlow === FlowTypes.WeightManagementFlowOptavia
                  ? 'The program includes:'
                  : `LifeMD GLP-1 Weight Management ${selectedPP?.paymentInterval.qty}-Month Program:`}
              </h3>
              <div className="flex flex-col gap-2">
                {(isBlueLineFlow
                  ? BLUE_LINE_ADVANTAGES
                  : willUseInsurance
                    ? WM_INSURANCE_DUMMY_ADVANTAGES
                    : WM_DUMMY_ADVANTAGES
                ).map((el) => (
                  <AdvantageItem advantage={el} key={el} showCustomIcon />
                ))}
              </div>
            </div>
            <div className="md:w-1/2">
              <h3 className="text-xl font-semibold text-primary-700">Frequently Asked Questions</h3>
              <WMQuestionsList questions={isBlueLineFlow ? BL_SELECT_PLAN_FAQ : FAQ_WM} />
            </div>
          </div>
        </>
      )}
      <div>
        <DynamicReviewsList className="py-10" reviewsList={DUMMY_REVIEWS_WM} />
        <div className="fluid bg-primary px-4 py-9">
          <h4 className="mb-7 text-center text-white">Backed by Research from</h4>
          <ListOfResearch />
        </div>
      </div>
      {/* {!willUseInsurance && (
        <div className="md:py-10">
          <WeightLossMoneyBack />
        </div>
      )} */}
      <Common.Button
        className={classNames('md:!w-[290px], sticky bottom-6 z-10 mt-auto')}
        color="blue"
        dataTestId="continue-btn"
        disabled={isLoading || !selectedPP}
        isLoading={isLoading}
        size={isMobile ? 'lg' : 'md'}
        fullWidthOnMobile
        onClick={handleSubmit}
      >
        Continue to checkout
      </Common.Button>
      {/* <div className="flex items-center gap-1 text-mSm text-primary-700 md:text-sm">
        <span>Backed by the</span>
        <span className="text-mSm font-bold md:text-sm">LifeMD Weight Loss Guarantee</span>
        <Common.Icon className="size-6 stroke-primary-700 text-white" name="check-circle" />
      </div> */}
    </div>
  );
};

export default SelectPlan;
